import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import * as serviceWorkerDevRegistration from './serviceWorkerDevRegistration'
import { StoreProvider } from "./utils/store";
import App from "./App";
import {
  Home,
  Login,
  Account,
  NavbarLink,
  Registration,
  Reset,
  Response,
  AccountUser,
  AccountGarage,
  AccountHelp,
  AccountSettings,
  AddCar,
  MyCars,
  MyShop,
  FindShop,
  History,
  UserHistory,
  Guard,
  AdminAccounts,
  Reviews,
} from "./components";
import "./index.module.scss";
import HttpsRedirect from "react-https-redirect";
import AddCarAsService from "./components/Account/Garage/AddCarAsService";
import "./index.css";
import LandingPageLayout from "./Layout/LandingPage";
import MainContent from "./Layout/MainContent";
import About from "./Layout/LandingPage/About";
import AllRepairs from "./routes/AllRepairs";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HttpsRedirect>
    <StoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <LandingPageLayout>
                  <MainContent />
                </LandingPageLayout>
              }
            />
            <Route
              path="/about"
              element={
                <LandingPageLayout>
                  <About />
                </LandingPageLayout>
              }
            />
            {/* <Route index element={<Home />} /> */}
            <Route
              path="/login"
              element={
                <LandingPageLayout>
                  <Login />
                </LandingPageLayout>
              }
            />
            <Route
              path="/register"
              element={
                <LandingPageLayout>
                  <Registration />
                </LandingPageLayout>
              }
            />
            <Route
              path="/register/:inviteID"
              element={
                <LandingPageLayout>
                  <Registration />
                </LandingPageLayout>
              }
            />
            <Route
              path="/:link"
              element={
                <LandingPageLayout>
                  <NavbarLink />
                </LandingPageLayout>
              }
            />
            <Route
              path="/account"
              element={
                <App>
                  <Account />
                </App>
              }
            >
              <Route index element={<AccountGarage />} />
              <Route path="user" element={<AccountUser />} />
              <Route
                path="garage/addcar"
                element={<Guard component={<AddCar />} userRole="USER" />}
              />
              <Route
                path="garage/mycars"
                element={<Guard component={<MyCars />} userRole="USER" />}
              />
              {/* <Route path="garage/shops" element={<Guard component={<FindShop />} userRole="USER" />} /> */}
              <Route path="garage/shops" element={<FindShop />} />
              <Route path="garage/service-history" element={<History />} />
              <Route
                path="garage/add-car-as-service"
                element={
                  <Guard component={<AddCarAsService />} userRole="SERVICE" />
                }
              />
              <Route
                path="garage/user-service-history"
                element={<UserHistory />}
              />
              <Route
                path="garage/service-history-fast-edit"
                element={<History fastServiceEdit="waiting" />}
              />
              {/* <Route path="garage/addrepair" element={<Guard component={<AddRepair />} userRole="SERVICE" />} /> */}
              <Route
                path="garage/myshop"
                element={
                  <Guard
                    component={<MyShop />}
                    userRole={["USER", "SERVICE"]}
                  />
                }
              />
              <Route
                path="garage/myshop/reviews"
                element={
                  <Guard
                    component={<Reviews />}
                    userRole={["USER", "SERVICE"]}
                  />
                }
              />
              <Route path="garage/admin/accounts" element={<AdminAccounts />} />
              <Route
                path="garage/admin/allRepairs"
                element={
                  <Guard component={<AllRepairs />} userRole={["ADMIN"]} />
                }
              />
              <Route path="help" element={<AccountHelp />} />
              <Route path="settings" element={<AccountSettings />} />
            </Route>
            <Route
              path="/response"
              element={
                <App>
                  <Response />
                </App>
              }
            />
            <Route
              path="/password-reset/:token"
              element={
                <LandingPageLayout>
                  <Reset />
                </LandingPageLayout>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  </HttpsRedirect>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
// serviceWorkerDevRegistration.register();
