import React from "react";
import { useParams } from "react-router-dom";

import "./index.scss";

export default function NavbarLink() {
  const { link } = useParams();

  const imageTutorialMobile = () => {
    switch (link) {
      case "about":
        return (
          <div className="link-wrapper">
            <img
              className="tutorial"
              src={require("../../assets/aboutus320.png")}
              alt="about us"
            />
          </div>
        );
      case "contact":
        return (
          <div className="login-wrapper">
            <div>
              <h1 className="nav_app_title">
                <span>Kontakt</span>
              </h1>
              <h3>
                Maciej M<span className="highlightR">H</span>: 572 208 629
              </h3>
              <h3>
                Łukasz M<span className="highlightR">H</span>: 572 208 629
              </h3>
              <h3>admin@motohistoria.pl</h3>
            </div>
          </div>
        );
      default:
        return (
          <div className="login-wrapper">
            <div>
              <h1 className="nav_app_title">
                <span>Produkty</span>
              </h1>
              <p className="about">
                Roczna subskrypcja na jedno auto to 35zł - płacisz raz,
                użytkujesz cały rok.
                <br />
                Korzyści z korzystania z MH:
                <ul>
                  <li>transparentna cyfrowa księga wieczysta pojazdu,</li>
                  <li>dostęp do zweryfikowanych warsztatów,</li>
                  <li>wiarygodne opinie</li>
                </ul>
              </p>
            </div>
          </div>
        );
    }
  };

  const imageTutorialDesktop = () => {
    switch (link) {
      case "about":
        return (
          <div className="link-wrapper">
            <img
              className="tutorial"
              src={require("../../assets/aboutus1300.png")}
              alt="about us"
            />
          </div>
        );
      case "contact":
        return (
          <div className="login-wrapper">
            <div>
              <h1 className="nav_app_title">
                <span>Kontakt</span>
              </h1>
              <h3>
                Maciej M<span className="highlightR">H</span>:{" "}
                <a className="highlightR" href="tel:572 208 629">
                  572 208 629
                </a>
              </h3>
              <h3>
                Łukasz M<span className="highlightR">H</span>:{" "}
                <a className="highlightR" href="tel:572 208 629">
                  572 208 629
                </a>
              </h3>
              <h3>admin@motohistoria.pl</h3>
            </div>
          </div>
        );
      default:
        return (
          <div className="light">
            <div>
              <div>
                <h1 className="nav_app_title">
                  <span>Produkty</span>
                </h1>
                <p className="about">
                  Roczna subskrypcja na jedno auto to 35zł - płacisz raz,
                  użytkujesz cały rok.
                  <br />
                  Korzyści z korzystania z MH:
                  <ul>
                    <li>transparentna cyfrowa księga wieczysta pojazdu,</li>
                    <li>dostęp do zweryfikowanych warsztatów,</li>
                    <li>wiarygodne opinie</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  if (window.screen.width >= 1366) {
    return imageTutorialDesktop();
  } else {
    return imageTutorialMobile();
  }
}
