import { CustomError } from "./customError";

export const getAllServices = (formData) => {
  return fetch(`/api/service/running-services`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" },
  }).then(async (res) => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const getAdminAllServices = async () => {
  return fetch(`/api/service/all-admin-services`, {
    method: "POST",
  }).then(async (res) => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};
export const getServicesByCar = (carID) => {
  return fetch(`/api/service/service-history/${carID}`, {
    method: "POST",
  }).then(async (res) => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const approveService = ({ serviceID }) => {
  return fetch(`/api/service/approve/${serviceID}`, {
    method: "POST",
  }).then(async (res) => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const updateService = (formData) => {
  return fetch(`/api/service/update`, {
    method: "PUT",
    body: formData,
  }).then(async (res) => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const archiveService = (formData) => {
  return fetch(`/api/service/archive`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: { "Content-Type": "application/json" },
  }).then(async (res) => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};

export const removeService = (formData) => {
  return fetch(`/api/service/remove/${formData.serviceID}`, {
    method: "DELETE",
  }).then(async (res) => {
    if (!res.ok) {
      const { name, message } = await res.json();
      throw new CustomError({ name, message });
    }
    return await res.json();
  });
};
