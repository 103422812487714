import React, { useEffect, useState } from "react";
import { getAdminAllServices } from "../requests/service-request";
import { Link } from "react-router-dom";

export default function AllRepairs() {
  const [allCars, setAllCars] = useState(null);
  useEffect(() => {
    getAdminAllServices().then((res) => {
      setAllCars(res.data);
    });
  }, []);

  function formatDate(date) {
    const d = new Date(date);
    return `${(d.getDate() < 10 ? "0" : "") + d.getDate()}.${
      (d.getMonth() < 9 ? "0" : "") + (d.getMonth() + 1)
    }.${d.getFullYear()} ${(d.getHours() < 10 ? "0" : "") + d.getHours()}:${
      (d.getMinutes() < 10 ? "0" : "") + d.getMinutes()
    }`;
  }
  return allCars ? (
    <div
      className="service-history-list-container"
      style={{ placeItems: "center" }}
    >
      <div
        className="service-history-list"
        style={{ display: "grid", gap: "1rem" }}
      >
        {allCars?.map((item) => {
          return (
            <div
              key={item._id}
              className="service-history-list-item"
              style={{
                backgroundColor: "white",
                padding: "0 1rem",
                borderRadius: "10px",
                maxWidth: "600px",
              }}
            >
              <div className="service-history-list-item-header">
                <h2>
                  Serwis:{" "}
                  <span style={{ color: "rgb(143, 53, 68)" }}>
                    {item.shop?.serviceName}
                  </span>
                </h2>
                <p>
                  Dodano: <strong>{formatDate(item.createdAt)}</strong>
                </p>
                <p>
                  Ostatnia aktualizacja:{" "}
                  <strong>{formatDate(item.updatedAt)}</strong>
                </p>
                <p>
                  Pojazd: {item.car?.brand} {item.car?.model}
                </p>
                <p>
                  Status:{" "}
                  <strong>
                    {item.archived
                      ? "Zakończony"
                      : item.shopApproved
                      ? "W trakcie"
                      : "Niezatwierdzony"}
                  </strong>
                </p>
              </div>
              {item.serviceDescription ? (
                <div className="service-history-list-item-body">
                  <p style={{ lineBreak: "anywhere" }}>
                    Opis naprawy: {item.serviceDescription}
                  </p>
                </div>
              ) : (
                <p>Brak opisu naprawy</p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {item.shop?.phone ? (
                  <p>{item.shop?.phone}</p>
                ) : (
                  <p>Brak numeru telefonu</p>
                )}
                {item.shop?.email ? (
                  <p>{item.shop?.email}</p>
                ) : (
                  <p>Brak adresu e-mail</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
}
