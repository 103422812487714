import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../utils/store";
import { validateForm } from "../../utils/validation";
import { registerRequest } from "../../requests/register-request";

import BuildIcon from "@mui/icons-material/Build";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import { Checkbox, FormControlLabel } from "@mui/material";
import FormItem from "../FormItem";

import "./index.scss";

export default function Registration() {
  const { inviteID } = useParams();
  const [userRole, setUserRole] = useState("SERVICE");
  const [formData, setFormData] = useState({
    role: userRole,
    confirmPassword: true,
  });
  const [affiliation, setAffiliation] = useState("");
  const [invalidator, setInvalidator] = useState({});
  const [readytoAPI, setReadytoAPI] = useState(false);
  const [clearPrompt, setClearPrompt] = useState({});
  const navigate = useNavigate();
  const [{ termsAccepted }, dispatch] = useStore();

  const { mutate } = useMutation(registerRequest, {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: (res) => {
      dispatch({
        type: "onSuccess",
        payload: { visible: true, message: "Dodano użytkownika" },
      });
      navigate("/response");
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  });

  const handleFormSubmit = (flag) => {
    setClearPrompt({});
    validateForm(formData, flag, setInvalidator, setReadytoAPI, dispatch);
  };

  useEffect(() => {
    if (readytoAPI && termsAccepted) {
      dispatch({ type: "onLoading", payload: "loading" });
      mutate({
        email: formData.email,
        password: formData.password,
        role: userRole,
        serviceName: userRole === "SERVICE" ? formData.shop : undefined,
        affiliation: affiliation || undefined,
        invitationID: inviteID || null,
      });
    }
    // eslint-disable-next-line
  }, [readytoAPI, termsAccepted]);

  const clearFormPrompt = (promptName) => {
    setClearPrompt({ ...clearPrompt, [promptName]: true });
  };

  const displayFormPrompt = (promptName) => {
    if (!invalidator[promptName] || clearPrompt[promptName]) return "hidden";
    else return "visible";
  };

  return (
    <div className="login-wrapper">
      <div className="register-container">
        <div
          className="register-main-section"
          onKeyDown={(e) => e.key === "Enter" && handleFormSubmit("register")}
        >
          <p>Rejestrujesz sie jako:</p>
          <div className="user-role-selection">
            <FormControlLabel
              label="klient"
              labelplacement="start"
              sx={{
                "&.MuiFormControlLabel-root .MuiTypography-root": {
                  font:
                    userRole === "USER"
                      ? "300 1.5rem var(--selectedFontFamily)"
                      : "300 1.5rem var(--mainFontFamily)",
                },
              }}
              control={
                <Checkbox
                  onChange={() => {
                    setUserRole("USER");
                  }}
                  checked={userRole === "USER"}
                  sx={{
                    color: "grey",
                    "&.Mui-checked": {
                      color: "var(--primary)",
                    },
                  }}
                  icon={<DirectionsCarFilledOutlinedIcon />}
                  checkedIcon={<DirectionsCarIcon />}
                />
              }
            />
            <FormControlLabel
              label="serwis"
              labelplacement="start"
              sx={{
                "&.MuiFormControlLabel-root .MuiTypography-root": {
                  font:
                    userRole === "SERVICE"
                      ? "300 1.5rem var(--selectedFontFamily)"
                      : "300 1.5rem var(--mainFontFamily)",
                },
              }}
              control={
                <Checkbox
                  onChange={() => {
                    setUserRole("SERVICE");
                  }}
                  checked={userRole === "SERVICE"}
                  sx={{
                    color: "grey",
                    "&.Mui-checked": {
                      color: "var(--primary)",
                    },
                  }}
                  label="klient"
                  icon={<BuildOutlinedIcon />}
                  checkedIcon={<BuildIcon />}
                />
              }
            />
          </div>
          <FormItem
            desc="Nazwa warsztatu"
            setValue={(value) =>
              setFormData((prev) => ({ ...prev, shop: value }))
            }
            show={userRole === "USER" ? false : true}
            clearPrompt={() => clearFormPrompt("shop")}
          />
          <p
            style={{
              display: userRole === "USER" ? "none" : "block",
              visibility: displayFormPrompt("shop"),
            }}
            className="form-validator-prompt"
          >
            Pole musi zawierać min 3 znaki
          </p>
          <FormItem
            desc="Adres email"
            setValue={(value) =>
              setFormData((prev) => ({ ...prev, email: value }))
            }
            clearPrompt={() => clearFormPrompt("email")}
          />
          <p
            style={{ visibility: displayFormPrompt("email") }}
            className="form-validator-prompt"
          >
            Niepoprawny email
          </p>
          <FormItem
            desc="Hasło"
            setValue={(value) =>
              setFormData((prev) => ({ ...prev, password: value }))
            }
            clearPrompt={() => clearFormPrompt("password")}
          />
          <p
            style={{ visibility: displayFormPrompt("password") }}
            className="form-validator-prompt"
          >
            Hasło to minimum 8 znaków, w tym duża litera i cyfra
          </p>
          <FormItem
            desc="Powtórz hasło"
            setValue={(value) =>
              setFormData((prev) => ({ ...prev, confirmPassword: value }))
            }
            clearPrompt={() => clearFormPrompt("confirmPassword")}
          />
          <p
            style={{ visibility: displayFormPrompt("confirmPassword") }}
            className="form-validator-prompt"
          >
            Hasła nie są takie same
          </p>
          <FormItem
            desc="Warsztat polecający (ID)"
            show={userRole === "USER" ? true : false}
            setValue={(value) => setAffiliation(value)}
            clearPrompt={() => {}}
          />
          <p
            style={{
              display: userRole === "USER" ? "block" : "none",
              visibility: "hidden",
            }}
            className="form-validator-prompt"
          >
            Nieprawidłowy identyfikator
          </p>
          <div
            className="register-button-update"
            onClick={() => handleFormSubmit("register")}
          >
            Rejestracja
          </div>
        </div>
      </div>
    </div>
  );
}
