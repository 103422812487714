import React, { useRef, useEffect } from "react";

import "./note-modal.scss";

export default function NoteModal({
  showingNoteModal,
  setShowingNoteModal,
  onAddNote,
  serviceItem,
  userPreview,
  handleserviceSaveMultipart,
}) {
  const textRef = useRef();

  useEffect(() => {
    textRef.current.value = serviceItem.serviceDescription;
  }, [serviceItem.serviceDescription]);

  const handleAddNote = () => {
    setShowingNoteModal(false);
    onAddNote(textRef.current.value);
  };

  const handleNoteClose = () => {
    setShowingNoteModal(false);

    if (!userPreview) {
      textRef.current.value = serviceItem.serviceDescription;
      onAddNote(undefined);
    }
  };

  return (
    <div
      className={`service-history-list-container-note-modal ${
        showingNoteModal ? "show" : ""
      }`}
    >
      <div className="note-modal-header">
        <span>
          {(serviceItem?.car?.brand || "Samochód") +
            " " +
            (serviceItem?.car?.model || "usunięty")}
        </span>

        <div className="note-modal-close" onClick={() => handleNoteClose()}>
          <div className="close"></div>
        </div>
      </div>
      {userPreview ? (
        false
      ) : (
        <button
          className="add-note-button"
          onClick={() => {
            handleAddNote();
            handleserviceSaveMultipart();
          }}
        >
          &#43;
        </button>
      )}
      <textarea
        ref={textRef}
        disabled={!!userPreview}
        placeholder="dodaj opis naprawy..."
        defaultValue={serviceItem.serviceDescription || ""}
      ></textarea>
    </div>
  );
}
